import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import rehypeReact from 'rehype-react';
import styled from 'styled-components';

import {
  bookFontFamily,
  contentHorizontalPadding,
  semiBoldFontFamily,
  mobileSize,
} from '../components/base_styles';
import WebsiteStats from '../components/case-studies/WebsiteStats';
import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';

const CaseStudyWrapper = styled.div`
  background: white;
  border-top: 4px solid #2ADCC7;
  margin: -120px auto 0 auto;
  max-width: 958px;
  padding: 64px 0;
  position: relative;
  z-index: 2;

  @media (max-width: ${mobileSize}) {
    margin: -10px 0 0 0;
    padding: 32px ${contentHorizontalPadding};
  }
`;

const Title = styled.h2`
  margin-bottom: 8px;
  padding: 0 122px;

  @media (max-width: ${mobileSize}) {
    font-size: 17px;
    line-height: auto;
    padding: 0;
  }
`;

const Subtitle = styled.h1`
  margin-bottom: 48px;
  padding: 0 122px;

  @media (max-width: ${mobileSize}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    padding: 0;
  }
`;

const CaseStudyContent = styled.div`
  h4, p {
    margin-bottom: 16px;
    padding: 0 122px;

    @media (max-width: ${mobileSize}) {
      padding: 0;
    }
  }

  &> div > p:first-of-type {
    font-family: ${bookFontFamily};
    font-size: 22px;
    letter-spacing: 0.25px;
    line-height: 32px;
    margin-bottom: 48px;

    @media (max-width: ${mobileSize}) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }

  .gatsby-resp-image-wrapper {
    margin-bottom: 48px;

    @media (max-width: ${mobileSize}) {
      margin-bottom: 24px;
    }
  }

  h6 > img {
    display: block;
    margin: 56px auto;
    max-width: 100%;
  }

  p + h3,
  h3 + h4 {
    margin-top: 88px;

    @media (max-width: ${mobileSize}) {
      margin-top: 32px;
    }
  }

  &> div > p:last-of-type {
    color: #979797;
    font-family: ${semiBoldFontFamily};
    font-size: 16px;

    a {
      font-family: ${semiBoldFontFamily};
      font-size: 16px;
      text-decoration: none;
    }
  }
`;

// renderer for custom markdown components
/* eslint-disable-next-line new-cap */
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'website-stats': WebsiteStats,
  },
}).Compiler;

export default function Template({ data }) {
  const { markdownRemark: { frontmatter, htmlAst } } = data;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Img
        fluid={frontmatter.coverImage.childImageSharp.fluid}
        style={{ maxHeight: '685px' }}
      />
      <CaseStudyWrapper>
        <Title>{frontmatter.title}</Title>
        <Subtitle>{frontmatter.subtitle}</Subtitle>
        <CaseStudyContent>
          { renderAst(htmlAst) }
        </CaseStudyContent>
      </CaseStudyWrapper>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        path: PropTypes.string,
        /* eslint-disable-next-line react/forbid-prop-types */
        coverImage: PropTypes.object,
      }),
      htmlAst: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
