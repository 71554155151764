import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  boldFontFamily,
  contentFullWidth,
  contentHorizontalPadding,
  headerLinkColor,
  mobileSize,
} from '../base_styles';

const WebsiteStatsWrapper = styled.div`
  background: #C5F9E9;
  color: ${headerLinkColor};
  margin-bottom: -65px;
  margin-left: calc(-50vw + 50%);
  position: relative;
  width: 100vw;

  @media (max-width: ${mobileSize}) {
    margin-bottom: -35px;
  }
`;

const WebsiteStatsInner = styled.div`
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 88px ${contentHorizontalPadding};
  text-align: center;

  @media (max-width: ${mobileSize}) {
    padding: 48px ${contentHorizontalPadding};
  }
`;

const WebsiteStatsIntro = styled.p`
  font-size: 22px;
  line-height: 28px;
  margin: 20px 0 40px 0 !important;
  padding: 0 !important;
`;

const WebsiteStatsRow = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 946px;

  @media (max-width: ${mobileSize}) {
    flex-direction: column;
  }
`;

const WebsiteStat = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 210px;

  @media (max-width: ${mobileSize}) {
    margin: 0 auto 16px auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: #2ADCC7;
    font-family: ${boldFontFamily};
    font-size: 32px;
    line-height: 34px;
  }

  p {
    color: ${headerLinkColor};
    font-size: 18px;
    line-height: 26px;
    padding: 0 !important;
  }
`;

const WebsiteStats = ({
  children, users, pageviews, time, bouncerate,
}) => (
  <WebsiteStatsWrapper>
    <WebsiteStatsInner>
      <h1>Website success</h1>

      <WebsiteStatsIntro>{children}</WebsiteStatsIntro>

      <WebsiteStatsRow>
        <WebsiteStat>
          <span>{users}</span>
          <p>increase in new users</p>
        </WebsiteStat>
        <WebsiteStat>
          <span>{pageviews}</span>
          <p>increase in page views</p>
        </WebsiteStat>
        <WebsiteStat>
          <span>{time}</span>
          <p>increase in time spent on the website</p>
        </WebsiteStat>
        <WebsiteStat>
          <span>{bouncerate}</span>
          <p>decrease in bounce rate</p>
        </WebsiteStat>
      </WebsiteStatsRow>
    </WebsiteStatsInner>
  </WebsiteStatsWrapper>
);

WebsiteStats.propTypes = {
  users: PropTypes.string.isRequired,
  pageviews: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  bouncerate: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default WebsiteStats;
